@import "../../../../../src/scss/index.scss";

.register {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: #A6D0FF36;

    &__navbar {
        background-color: $ids-white;
    }

    &__content {
        width: 100%;

        &--main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 40px;
            gap: 20px;
            background-color: $ids-white;
            margin: 30px;

            @media (max-width: 768px) {
                padding: 5px;

            }

            &--bg {
                position: absolute;
                right: 0;
                width: 60%;
                z-index: 1;
                object-fit: cover;
            }
        }

        &-title {
            font-size: 34px;
            font-weight: 700;
            color: $ids-blue;
            line-height: 48px;

            @media (max-width: 768px) {
                font-size: 24px;
            }
        }

        &--section {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 35px;
            flex-direction: row;

            @media (max-width: 768px) {
                flex-direction: column;
                align-items: center;

            }
        }

        &--info {
            position: relative;
            width: 50%;
            height: 847px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            flex-direction: column;
            background: linear-gradient(199.71deg, #4B85C6 -6.72%, #5CA4F4 105.09%);
            border-radius: 25px;
            padding: 15px;

 

            @media (min-width: 768px) {
                margin-top: 58px;
            }

            @media (max-width: 768px) {
                width: 90%;
            }

            @media (max-width: 480px) {
                width: 100%;
                height: auto;
            }

            &--bg {
                position: absolute;
                right: 0;
                width: 70%;
                z-index: 0;
                opacity: 8%;
            }
 

            &--title {
                position: relative;
                z-index: 1;

                width: 100%;
                padding-top: 40px;
                padding-left: 15px;
                padding-right: 15px;
                align-items: center;
                display: flex;
                flex-direction: column;
                text-align: center;

                h2 {
                    font-family: 'M_Plus_1p', sans-serif;
                    font-weight: 400;
                    font-size: 36px;
                    color: $ids-white;

                    @media (max-width: 480px) {
                        font-weight: 500;
                        font-size: 20px;
                    }
                }

                strong {
                    font-family: 'M_Plus_1p', sans-serif;
                    font-weight: 900;
                    font-size: 36px;
                    color: $ids-white;

                    @media (max-width: 480px) {
                        font-size: 20px;
                    }
                }

                p {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 36px;
                    color: $ids-white;

                    @media (max-width: 480px) {
                        font-size: 20px;
                    }
                }

                button {
                    position: relative;
                    z-index: 1;
                    width: 90%;
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    gap: 13px;
                    background-color: $ids-white;
                    color: $ids-dark-blue;
                    border: none;
                    border-radius: 10px;
                    font-size: 24px;
                    font-weight: 800;
                    cursor: pointer;
                    transition: background-color 0.3s;
                    margin-top: 45px;

                    @media (max-width: 480px) {
                        gap: 0px;
                        font-size: 16px;
                        height: 60px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
            }

            &--contact {
                position: relative;
                z-index: 1;
                background: #FFFFFF66;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border: 1px solid $ids-white;
                border-radius: 8px;
                padding: 40px;
                margin-top: 142px;
                width: auto;
                
                @media (max-width: 480px) {
                    padding: 20px;
                    margin-top: 52px;
                }

                p,
                strong {
                    color: black;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media (max-width: 390px) {
                        font-size: 20px;
                    }
                }
            }
        }

        &--form {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            flex-direction: column;

            @media (max-width: 768px) {
                width: 90%;
                margin-top: 20px;
            }

            &--options{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;

                @media (max-width: 768px) {
                    flex-direction: column;
                    align-items: center;
                    gap: 15px;
                }

                input[type="checkbox"] {
                    -webkit-appearance: none;  
                    -moz-appearance: none;
                    appearance: none;
                    width: 21.42px;
                    height: 21.2px;
                    border: 2px solid $ids-dark-blue;   
                    border-radius: 6px;   
                    display: inline-block;
                    position: relative;
                    cursor: pointer;
                    transition: all 0.2s ease;
                  
                    &:checked {
                      background-color: $ids-dark-blue;   
                   
                      &::before {
                        content: "";
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        background-color: $ids-dark-blue;  
                        border-radius: 3px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      }
                    }
                  }
                  
                legend{
                    font-size: 16px;
                    font-weight: 700;
                    color: $ids-blue;
                }

                &--comercial{
                    width: 50%;
                    display: flex;
                    justify-content: start;
                    align-items: start;
                    flex-direction: column;
                    gap: 5px;

                    @media (max-width: 768px) {
                        width: 100%;
                    }
                    
                    &--option{
                        width: auto;
                        display: flex;
                        flex-direction: row;
                        white-space: nowrap;
                        gap: 5px;
                    }
                }
                &--email{
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 5px;

                    @media (max-width: 768px) {
                        width: 100%;
                    }

                    &--option{
                        width: auto;
                        display: flex;
                        flex-direction: row;
                        white-space: nowrap;
                        gap: 5px;
                    }
                }
            }

            &--message{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 25px;

                textarea {
                    width: 100%;
                    height: 212px;
                    border-radius: 8px;
                    border: 2px solid $ids-blue;
                }

                &--group{
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    gap: 15px;

                    input[type="checkbox"] {
                        -webkit-appearance: none;  
                        -moz-appearance: none;
                        appearance: none;
                        width: 21.42px;
                        height: 21.2px;
                        border: 2px solid $ids-dark-blue;   
                        border-radius: 6px;   
                        display: inline-block;
                        position: relative;
                        cursor: pointer;
                        transition: all 0.2s ease;
                      
                        &:checked {
                          background-color: $ids-dark-blue;   
                          border-color: transparent;   
                      
                          &::before {
                            content: "";
                            position: absolute;
                            width: 12px;
                            height: 12px;
                            background-color: $ids-dark-blue;  
                            border-radius: 3px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                          }
                        }
                      }
 
                    p,a{
                        font-size: 13px;
                        font-weight: 700;
                        line-height: 19.31px;
                        color: $ids-blue;
                    }
                }
            }

            form {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 25px;
            }

            label,
            input {
                width: 100%;
            }

            input {
                border: none;
                padding: 5px;
                border-bottom: 2px solid $ids-blue;
                ;
            }

            label {
                font-size: 20px;
                font-weight: 700;
                color: $ids-blue;
            }

            strong {
                color: red;
            }
        }


    }


    &__subtitle {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        padding-left: 10%;
        padding-right: 10%;
        margin-top: 44px;
        flex-direction: column;
        text-align: center;


        h4 {
            font-size: 32px;
            font-weight: 700;
        }

        p {
            font-weight: 600;
            font-size: 20px;
        }

        @media (max-width: 768px) {
            font-size: 14px;
            padding-left: 5%;
            padding-right: 5%;
        }

        @media (max-width: 1540px) {
            font-size: 16px;
        }

    }

    &__header {
        position: relative;
        text-align: center;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 64px;
            color: $ids-blue;
            margin-bottom: 10px;

            @media (max-width: 768px) {
                font-size: 30px;
            }
        }

        h2 {
            font-size: 36px;
            color: $ids-gray;
            margin-bottom: 30px;

            @media (max-width: 768px) {
                font-size: 24px;
            }
        }


    }

    &__tabs {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: transparent;

        &--content {
            display: flex;
            justify-content: center;
        }
    }

    &__tab {
        height: 70px;
        padding: 18px;
        font-size: 18px;
        font-weight: 700;
        color: $ids-disabled;
        border: none;
        background: $ids-white;
        cursor: pointer;
        transition: background 0.3s;
        width: 188px;
        text-align: center;

        &:first-child {
            border-top-left-radius: 20px;
        }

        &:last-child {
            border-top-right-radius: 20px;
        }

        &.active {
            color: $ids-blue;
        }
    }

    &__footer {
        margin-top: auto;
    }
}

#submit{
    width: 247px;
    height: 60px;
    font-size: 24px;
    font-weight: 700;
    border-radius: 8px;
    border: none;
    color: $ids-white;
    background-color: $ids-blue;

    @media (max-width: 480px) {
        height: 50px;
        font-size: 16px;
    }
}

input, textarea {
    position: relative;
    z-index: 2;
}
