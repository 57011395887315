@import "../../../../../scss/index.scss";
.plan-card {
    background-color: $ids-planCard-bg;
    padding: 20px;
    margin: 15px;
    border-radius: 15px;
    text-align: center;
    width: 292px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;  
    position: relative;
    z-index: 2;
    transition: transform 0.3s ease, box-shadow 0.3s ease;  

    &:hover {
        transform: scale(1.04);  
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);  
        background-color: #5ca8ff36;
    }

    li{
        color: $ids-blue;
        font-weight: 700;
    }
  
    &__header {
      margin-bottom: 20px;
    }
  
    &__title {
      font-size: 24px;
      color: #007bff;
      margin: 0;
    }
  
    &__subtitle {
      font-size: 12px;
      color: $ids-blue;
      margin: 0;
      font-weight: 700;
    }
  
    &__price-section {
      margin: 20px 0;
    }
  
    &__price {
      font-size: 32px;
      color: #007bff;
    }
  
    &__price-info {
      font-size: 12px;
      line-height: 17.82px;
      font-weight: 700;
      color: $ids-blue;
      padding-left: 20%;
      padding-right: 20%;
    }
  
    &__features {
      list-style: none;
      padding: 0;
      margin: 0 0 20px 0;
      padding-bottom: 40px;
  
      &__feature {
        font-size: 14px;
        color: #007bff;
        margin: 5px 0;
      }
    }
  
    &__button {
      background-color: $ids-white;
      color:$ids-blue;
      padding: 10px 20px;
      border: none;
      border-radius: 25px;
      font-size: 16px;
      cursor: pointer;
      width: 100%;
      margin-top: auto;
      font-weight: 700;
    }
  }
  