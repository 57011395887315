@import "../../../../../src/scss/index.scss";

.pricing {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__products {
        display: flex;
        justify-content: center;
        padding: 20px;

        img {
            width: 100%;
            max-width: 1200px;
            height: auto;

            @media (max-width: 768px) {
                display: none;
            }
        }

    }

    &__content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        gap: 10px;
        margin-top: 61px;
        padding-bottom: 30px;
        padding-top: 20px;
        position: relative;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        padding-left: 0;
        padding-right: 0;
        scroll-padding-left: 20px;

        &::-webkit-scrollbar {
            display: none;
        }

        @media (max-width: 853px) {
            gap: 10px;
            scroll-padding-left: 10px;
            justify-content: flex-start;
        }

        &>* {
            scroll-snap-align: start;
            flex-shrink: 0;
            min-width: 250px;
        }

        &--bg {
            position: absolute;
            top: 0;
            right: 0;
            width: 60%;
            height: 100%;
            z-index: 1;
            object-fit: cover;
        }

        &--solutions {
            width: 100%;
            background: linear-gradient(247.05deg, rgba(166, 208, 255, 0.3) 50.06%, rgba(255, 255, 255, 0.3) 96.83%);
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            gap: 44px;
            padding-top: 20px;

            @media (max-width: 768px) {
                flex-direction: column;
                gap: 10px;
                align-items: center;
            }

            h3 {
                padding-bottom: 15px;
            }

            &--left {
                gap: 30px;


            }

            &--left--card,
            &--right--card {
                background-color: #A6D0FF36;
                width: 506px;
                margin-bottom: 30px;

                @media (max-width: 768px) {
                    width: 306px;

                }

                &--title {
                    background-color: #9BCAFF;
                    border-radius: 12px 12px 0px 0px;
                    width: 100%;
                    padding-top: 9px;
                    padding-bottom: 12px;
                    text-align: center;
                }

                &--description {
                    padding-left: 40px;
                    padding-right: 40px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    text-align: center;
                    color: $ids-dark-blue;
                }
            }
        }
    }

    &__subtitle {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        padding-left: 10%;
        padding-right: 10%;
        margin-top: 44px;
        flex-direction: column;
        text-align: center;


        h4 {
            font-size: 32px;
            font-weight: 700;
        }

        p {
            font-weight: 600;
            font-size: 20px;
        }

        @media (max-width: 768px) {
            font-size: 14px;
            padding-left: 5%;
            padding-right: 5%;
        }

        @media (max-width: 1540px) {
            font-size: 16px;
        }

        &--button {
            height: 51px;
            width: 292px;
            color: $ids-white;
            background-color: $ids-blue;
            border-radius: 25px;
            border: none;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 70px;
            margin-top: 18px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.323);
        }
    }

    &__header {
        position: relative;
        text-align: center;
        background: #A6D0FF36;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 64px;
            color: $ids-blue;
            margin-bottom: 10px;

            @media (max-width: 768px) {
                font-size: 30px;
            }
        }

        h2 {
            font-size: 36px;
            color: $ids-gray;
            margin-bottom: 30px;

            @media (max-width: 768px) {
                font-size: 24px;
            }
        }

        &--button {
            height: 51px;
            width: 292px;
            color: $ids-white;
            background-color: $ids-blue;
            border-radius: 25px;
            border: none;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 70px;
        }
    }

    &__tabs {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: transparent;

        &--content {
            display: flex;
            justify-content: center;
        }
    }

    &__tab {
        height: 70px;
        padding: 18px;
        font-size: 18px;
        font-weight: 700;
        color: $ids-disabled;
        border: none;
        background: white;
        cursor: pointer;
        transition: background 0.3s;
        width: 188px;
        text-align: center;

        &:first-child {
            border-top-left-radius: 20px;
        }

        &:last-child {
            border-top-right-radius: 20px;
        }

        &.active {
            color: $ids-blue;
        }
    }

    &__footer {
        margin-top: auto;
    }
}