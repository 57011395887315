*{
  margin: 0 ;
  padding: 0;
  box-sizing: border-box;
}

html,body{
  width: 100%;
  height: 100%;
  font-family: M_Plus_1p;
}