@import "../../../scss/index.scss";
.navbar {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: $ids-white;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;  
    z-index: 10;  
   
  
    @media (max-width: 880px) {
      padding-left: 20px;
      padding-right: 20px;
      height: 80px;
    }
  
    &__menu {
      width: 41%;
      display: flex;
      align-self: center;
      align-items: center;
      transition: max-height 0.3s ease;
 
      a{
        cursor: pointer;
      }
  
      @media (max-width: 880px) {
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        flex-direction: column;
        background-color: $ids-white;
        max-height: 0;  
        overflow: hidden;
      }
  
      &--open {
        background-color: $ids-white;
        opacity: 1;
        position: absolute;
        z-index: 1;
        max-height: 300px;  
      }
  
      &--options {
        width: 100%;
        display: flex;
        text-decoration: none;
        align-items: center;
        justify-content: center;
        color: $ids-blue;
        font-weight: 700;
        font-size: 16px;
        line-height: 23.76px;
        font-family: "M PLUS 1p", sans-serif;
  
        @media (max-width: 880px) {
          font-size: 14px;
          display: block;
          width: 100%;
          padding: 10px;
          text-align: center;
        }
      }
    }
  
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 25px;

        @media (max-width: 880px) {
            display: none;  
          }
      
          &--register, &--login {
            cursor: pointer;
            border: none;
          }

        &--register {
            height: 39px;
            width: 114px;
            color: $ids-blue;
            background-color: transparent;
            border: none;
            font-weight: 700;
            font-size: 16px;
            cursor: pointer;

            @media (max-width: 820px) {
                width: 100px;
            }
        }

        &--login {
            height: 39px;
            width: 112px;
            color: $ids-white;
            background-color: $ids-blue;
            border-radius: 25px;
            border: none;
            cursor: pointer;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.323);

            @media (max-width: 880px) {
                width: 100px;
            }
        }
    }
  
    &__toggle {
      display: none;
      flex-direction: column;
      cursor: pointer;
      position: relative;  
      z-index: 1;  
    
  
      @media (max-width: 880px) {
        display: flex;
      }
  
      span {
        height: 3px;
        width: 25px;
        background-color: $ids-blue;
        margin: 4px 0;
      }
    }
  }
  